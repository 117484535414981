@import '@fontsource/roboto';

form div {
  margin: 20px 0;
}

input,
textarea {
  resize: none;
  height: 25px;
  width: 100%;
  padding-right: 40px;
  background-color: snow;
  font-size: var(--fontSize);
  font-family: "Roboto", sans-serif;
  font-weight: 200 !important;
  border-color: var(--white);
  border-style: none;
  color: black;
  border-radius: 0%;
  box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.inputQuartet {
  border-style: unset;
  border: 1px solid gray;
  background-color: rgba(255, 255, 255, 0.55);
}
.submitButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 25px;
  padding: 20px 40px;
  color: var(--white);
  background-color: transparent;
  font-size: var(--fontSize);
  border: 1px solid var(--white);
}
.submitButton:hover {
  cursor: pointer;
  height: 25px;
  padding-right: 40px;
  color: var(--black);
  background-color: var(--white);
  font-size: var(--fontSize);
  border: 1px solid var(--white);
}
.inputName {
  margin-right: 10px;
}
.textArea {
  width: 100%;
  height: 200px;
  font-family: "Roboto", sans-serif;
  font-size: var(--fontSize);
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
