.text {
}

.row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 3vh;
  margin-bottom: 3vh;
  border-bottom: 1px solid snow;
}

.row:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}
.align {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
