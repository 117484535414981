.row {
  display: flex;
  flex-direction: "row";
  width: var(--contentWidth);
  justify-content: space-between;
  padding: 2vh 0;
  min-height: fit-content;

  border-bottom: 1px solid black;
}
.row:last-child {
  border-bottom: unset;
}
.column {
  display: flex;

  text-align: end;
}

@media only screen and (max-width: 600px) {
  .row {
    width: 90vw;
  }
}
