@import '@fontsource/roboto';

.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 200 !important;
  font-size: var(--fontSize);
}
button,
.button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: none; /* 2 */
  cursor: pointer; /* 3 */
  -webkit-border-fit: border !important;
}
h1 {
  font-weight: 200;
}
a {
  color: rgb(160, 109, 109);
  font-weight: 400 !important;
}
p {
  margin: 0px;
}

input:focus-visible,
textarea:focus-visible {
  outline: 1px solid rosybrown !important;
  box-shadow: none !important;
}
li,
ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

:root {
  /*   --black: rgb(16, 18, 17); */
  --white: snow;
  /*   --black: rgb(34, 36, 35); */
  --black: rgb(56, 48, 48);
  --lila: #17041b;
  --contentWidth: 30vw;
  --mobileHeightContent: 70vh;
  --mobileHeightContent: calc(var(--vh, 1vh) * 70);
  --fontSize: 15px;
  /*   --gradient: linear-gradient(180deg, rgb(56, 48, 48) ;, rgb(34, 36, 35)); */
}

/* TABLET */
@media only screen and (max-width: 1100px) {
  :root {
    /*   --black: rgb(16, 18, 17); */
    --white: snow;
    /*   --black: rgb(34, 36, 35); */
    --black: rgb(56, 48, 48);
    --lila: #17041b;
    --contentWidth: 40vw;
    --fontSize: 14px;
    /*   --gradient: linear-gradient(180deg, rgb(56, 48, 48) ;, rgb(34, 36, 35)); */
  }
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  :root {
    /*   --black: rgb(16, 18, 17); */
    --white: snow;
    /*   --black: rgb(34, 36, 35); */
    --black: rgb(56, 48, 48);
    --lila: #17041b;
    --contentWidth: 100vw;
    --fontSize: 13px;

    /*   --gradient: linear-gradient(180deg, rgb(56, 48, 48) ;, rgb(34, 36, 35)); */
  }
}
.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}

.container {
  position: absolute;
  scroll-snap-type: y proximity;
  display: flex;
  flex-direction: column;
  right: 0px;
  top: 10vh;
  top: calc(var(--vh, 1vh) * 10);
  height: 80vh;
  height: calc(var(--vh, 1vh) * 80);
  width: 50vw;
  color: snow;
  padding: 0px 5vw;
  z-index: 0;
  text-align: start;
  letter-spacing: 1px;
  line-height: 1.7;
  overflow: scroll;
  animation: fadeIn 2s linear;
  -webkit-animation: fadeIn 2s linear;
}
.container > div,
.container4tet > div {
  position: relative;
  margin: auto;
}

.container4tet {
  position: absolute;
  display: flex;
  height: 80vh;
  height: calc(var(--vh, 1vh) * 80);
  width: 50vw;
  color: black;
  z-index: 0;
  scroll-snap-type: y proximity;
  flex-direction: column;
  top: 10vh;
  top: calc(var(--vh, 1vh) * 10);
  left: 0px;
  padding: 0px 5vw;
  text-align: start;
  letter-spacing: 1px;
  line-height: 1.7;
  overflow: scroll;
  animation: fadeIn 2s linear;
  -webkit-animation: fadeIn 2s linear;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* .contentContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: var(--contentWidth);
  height: 80vh;
  z-index: 0;
  text-align: start;
  letter-spacing: 1px;
  line-height: 1.7;
  overflow: scroll;
} */

.buttonDark {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: fit-content;

  width: 140px;
  font-size: var(--fontSize);
  animation: fadeIn 2s;
  padding: 12px 40px;
  color: rosybrown;
  background-color: transparent;
  font-size: var(--fontSize);
  border: 1px solid rosybrown;
  -webkit-animation: fadeIn 2s;
}
.buttonDark:hover,
.buttonFullLengthDark:hover {
  color: black;
  font-size: var(--fontSize);
  border: 1px solid black;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: fit-content;
  width: 140px;
  font-size: var(--fontSize);
  animation: fadeIn 1.5s;
  padding: 12px 40px;
  color: var(--white);
  background-color: transparent;
  border: 1px solid var(--white);
  -webkit-animation: fadeIn 1.5s;
}
.buttonFullLength {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: fit-content;
  margin: 30px auto;
  width: 100%;
  animation: fadeIn 1.5s;
  padding: 12px 40px;
  color: var(--white);
  background-color: transparent;
  font-size: var(--fontSize);
  border: 1px solid var(--white);
  -webkit-animation: fadeIn 1.5s;
}
.buttonFullLengthDark {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: fit-content;
  margin: 30px auto;
  width: 100%;
  animation: fadeIn 1.5s;
  padding: 12px 40px;
  color: rosybrown;
  background-color: transparent;
  font-size: var(--fontSize);
  border: 1px solid rosybrown;
  -webkit-animation: fadeIn 1.5s;
}
.button:hover,
.buttonFullLength:hover {
  color: var(--black);
  background-color: var(--white);
  border: 1px solid var(--white);
}

.rowSpacing {
  margin-bottom: 4vh;
}
/* .fadeIn {
  width: var(--contentWidth);
  margin: auto;
  animation: fadeIn 2s linear;
  -webkit-animation: fadeIn 2s linear;
} */
* {
  scrollbar-width: none;
  box-sizing: border-box; /* Firefox implementation */
}
*::-webkit-scrollbar {
  /*   width: 0.5em;
 */
  display: none;
}
*::-webkit-scrollbar-thumb {
  background-color: snow;
  outline: 1px solid snow;
}
/* hide scrollbar in firefox
 */

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--black);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--black);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--black);
}

@media only screen and (max-width: 600px) {
  .App {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 400 !important;
    font-size: var(--fontSize);
  }

  .row {
    display: flex;
    flex-direction: row;
  }
  .column {
    display: flex;
    flex-direction: column;
  }

  .container {
    position: absolute;
    display: flex;
    top: 0px;
    right: 0px;
    height: var(--mobileHeightContent);
    margin-top: 17vh;
    margin-top: calc(var(--vh, 1vh) * 17);
    width: var(--contentWidth);
    color: snow;
    z-index: 5;
    animation: fadeIn 0.5s linear;
    -webkit-animation: fadeIn 0.5s linear;
  }
  .container4tet {
    position: absolute;
    display: flex;
    top: 0px;
    right: 0px;
    height: var(--mobileHeightContent);
    margin-top: 17vh;
    margin-top: calc(var(--vh, 1vh) * 17);
    width: var(--contentWidth);
    color: black;
    z-index: 5;
  }
  .contentContainer {
    display: unset;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 0;
    height: var(--mobileHeightContent);
    padding: 0 5vw;
    box-sizing: border-box;
    z-index: 5;
    text-align: start;
    font-family: "Roboto", sans-serif;
    letter-spacing: 1px;
    line-height: 1.7;
    letter-spacing: 0.1em;
    overflow: overlay;
  }
  .rowSpacing {
    margin-bottom: 3vh;
  }
  .fadeIn {
    width: 90vw;
    animation: fadeIn 1s linear 0s;
    -webkit-animation: fadeIn 1s linear 0s;
  }

  p {
    margin: 0px;
  }
  *::-webkit-scrollbar {
  }
  *::-webkit-scrollbar-thumb {
  }
}
