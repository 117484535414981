@import '@fontsource/noto-sans';
@import '@fontsource/roboto';

.container {
  position: relative;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw; /* Fallback for browsers that do not support Custom Properties */
  background-color: #130215;
  /*   overflow: hidden; */

  /*   background-image: linear-gradient(180deg, #130215, #000000);
 */
}
.quartet {
  position: relative;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw; /* Fallback for browsers that do not support Custom Properties */
  /*   background-image: linear-gradient(
    180deg,
    rgb(255, 255, 255),
    rgb(237, 237, 237)
  ); */
}
.icon {
  height: 5vh;
  fill: transparent;
  display: flex;
  align-items: center;
  stroke: snow;
  margin: 0 5px;
}
svg {
  height: 100%;
}
.iconLeft {
  transform: scaleX(-1);
  stroke: rosybrown;
}
.imageContainer {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  right: 0;
  opacity: 1;
  transition: 1s ease;
  object-fit: cover;
  /*background-image: linear-gradient(180deg, rgb(56, 48, 48), rgb(36, 33, 33));
  */
  z-index: 3;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  -ms-transition: 1s ease;
  -o-transition: 1s ease;
}
.image {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  right: 0;
  opacity: 0;
  transition: 1s ease;
  object-fit: cover;
  z-index: 4;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  -ms-transition: 1s ease;
  -o-transition: 1s ease;
}
.imageQuartet {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  left: 0;
  opacity: 0;
  transition: 1s ease;
  object-fit: cover;
  z-index: 4;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  -ms-transition: 1s ease;
  -o-transition: 1s ease;
}
.transition {
  width: 100vw !important;
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  -ms-transition: 1s ease;
  -o-transition: 1s ease;
}
.active {
  opacity: 1;
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  -ms-transition: 1s ease;
  -o-transition: 1s ease;
}
.photoSidebar {
  width: 50vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  position: absolute;
  left: 0;
  object-fit: cover;
  /*   opacity: 1; */
}
.photoSidebarQuartet {
  width: 50vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  position: absolute;
  right: 0;
  left: auto;
  object-fit: cover;
}
.main {
  text-align: start;
  font-family: "Roboto";
  font-size: 7vh;
  font-size: calc(var(--vh, 1vh) * 7);
  font-weight: 900;
  z-index: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.boxTop {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  z-index: 5;
  text-decoration: none;
}
.boxBottom,
.boxBottomQuartet {
  position: absolute;
  bottom: 0px;
  z-index: 5;
  right: 0px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.animationBoxBottom {
  animation-name: boxBottom;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-duration: 2s;
}
.animationBoxTop {
  animation-name: boxTop;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-duration: 2s;
}
@keyframes boxBottom {
  0% {
    right: 0px;
  }
  50% {
    right: 100px;
  }
  70% {
    right: 0px;
  }
  75% {
    right: 5px;
  }
  80% {
    right: 0px;
  }
}

@keyframes boxTop {
  0% {
    left: 0px;
  }
  50% {
    left: 100px;
  }
  70% {
    left: 0px;
  }
  75% {
    left: 5px;
  }
  80% {
    left: 0px;
  }
}
.first {
  color: var(--white);
}
.second {
  display: flex;
  align-items: center;
  color: transparent;
  -webkit-text-stroke: 1px var(--white);
}
.second:hover {
  color: var(--white);
  cursor: pointer;
}
.firstDark {
  color: rosybrown;
  cursor: pointer;
}
.secondDark {
  color: transparent;
  -webkit-text-stroke: 1px rosybrown;
}
.secondDark:hover {
  color: rosybrown;
  cursor: pointer;
}
.nav {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 6;
}
.navQuartet {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 6;
}

.link {
  text-decoration: none;
}
.navPoint {
  color: transparent;
  -webkit-text-stroke: 1px var(--white);
  text-align: start;
  font-family: "Roboto";
  font-size: 7vh;
  font-size: calc(var(--vh, 1vh) * 7);

  font-weight: 800;
  cursor: pointer;
}
.navPoint:hover {
  color: snow;
}
.navPointActive {
  color: snow;
}
.navPointActiveQuartet {
  color: rosybrown !important;
}
.navPointQuartet {
  color: transparent;
  -webkit-text-stroke: 1px rosybrown;
  text-align: end;
  font-family: "Roboto";
  font-size: 7vh;
  font-size: calc(var(--vh, 1vh) * 7);

  font-weight: 800;
  cursor: pointer;
}
.navPointQuartet:hover {
  color: rosybrown;
}

.row {
  display: flex;
  flex-direction: row;
  height: var(--mobileHeightContent);
}

.screen {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.rowToRight {
  width: 200vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  display: flex;
  flex-direction: row;
  transition: ease-in-out 0.7s;
  transform: translateX(-100vw);
  -webkit-transform: translateX(-100vw);
  -moz-transform: translateX(-100vw);
  -ms-transform: translateX(-100vw);
  -o-transform: translateX(-100vw);
}
.rowToLeft {
  width: 200vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  display: flex;
  flex-direction: row;
  transition: ease-in-out 0.7s;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}
.doubleWidth {
  width: 200vw;
  display: flex;
}
.home {
  object-position: top;
}
.news {
  object-position: 70% 50%;
}
.about {
  object-position: 72% 50%;
}
.projects {
  object-position: top;
}
.live {
  object-position: top;
}
.contact {
  object-position: 45% top;
}
.homeQuartet {
  object-position: top;
}
.aboutQuartet {
  object-position: 0% 20%;
}
.musicQuartet {
  object-position: 50% 20%;
}
.videoQuartet {
  object-position: 0% 20%;
}
.liveQuartet {
  object-position: 0% 20%;
}

@media only screen and (max-width: 600px) {
  .home {
    filter: none !important;
    -webkit-filter: none !important;
  }
  .news {
    object-position: 70% 50%;
  }
  .about {
    object-position: 72% 50%;
  }
  .projects {
    object-position: 70% 50%;
  }
  .live {
    object-position: 50% 50%;
  }

  .contact {
    object-position: 27% 50%;
  }
  .homeQuartet {
    object-position: 54% 50%;
  }
  .newsQuartet {
    object-position: 45% 50%;
  }
  .aboutQuartet {
    object-position: 45% 50%;
  }
  .videoQuartet {
    object-position: 45% 50%;
  }
  .liveQuartet {
    object-position: 52% 50%;
  }
  .container {
    position: relative;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw; /* Fallback for browsers that do not support Custom Properties */
    background-color: transparent;
    /*   overflow: hidden; */

    /*   background-image: linear-gradient(180deg, #130215, #000000);
   */
  }
  .imageContainer {
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    opacity: 0;
    object-fit: cover;
    background-image: linear-gradient(180deg, rgb(56, 48, 48), rgb(36, 33, 33));
    z-index: 3;
    /*     transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease; */
  }
  .image {
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    opacity: 0;
    filter: brightness(50%);
    object-fit: cover;
    z-index: 4;
    /*transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    */
    -webkit-filter: brightness(40%);
    -webkit-filter: brightness(50%);
  }

  .imageQuartet {
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: absolute;
    filter: brightness(100%);
    left: 0;
    opacity: 0;
    object-fit: cover;
    z-index: 4;
    -webkit-filter: brightness(100%);
    /*     transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
  } */
  }
  .active {
    opacity: 1;
    transition: 1s ease;
    -webkit-transition: 1s ease;
    -moz-transition: 1s ease;
    -ms-transition: 1s ease;
    -o-transition: 1s ease;
  }
  .main {
    text-align: start;
    font-family: "Roboto";
    font-size: 7vh;
    font-size: calc(var(--vh, 1vh) * 7);

    font-weight: 900;
    z-index: 5;
  }
  .boxTop {
    position: absolute;
    cursor: pointer;
    top: 0px;
    left: 0px;
    z-index: 5;
    text-decoration: none;
  }
  .boxBottom {
    width: 100vw;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    cursor: pointer;
    bottom: 0px;
    right: 0px;
    z-index: 5;
    text-decoration: none;
  }

  .boxBottomQuartet {
    width: 100vw;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    bottom: 0px;
    right: 0px;
    z-index: 5;
    text-decoration: none;
  }
  .nav {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100vw;
    padding-bottom: 0.5vh;
    border-bottom: 1px solid snow;
    height: fit-content;
    justify-content: space-between;
    top: 10vh;
    top: calc(var(--vh, 1vh) * 10);

    z-index: 6;
  }
  .navQuartet {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100vw;
    padding-bottom: 0.5vh;
    border-bottom: 1px solid rosybrown;
    height: fit-content;
    justify-content: space-between;
    top: 10vh;
    top: calc(var(--vh, 1vh) * 10);

    z-index: 6;
  }
  .link {
    text-decoration: none;
  }
  .navPoint {
    color: transparent;
    -webkit-text-stroke: 1px var(--white);
    text-align: start;
    font-family: "Roboto";
    font-size: 4vw;
    font-weight: 800;
    cursor: pointer;
  }
  .navPointQuartet {
    color: transparent;
    -webkit-text-stroke: 1px rosybrown;
    text-align: end;
    font-family: "Roboto";
    font-size: 4vw;
    font-weight: 800;
    cursor: pointer;
  }

  .row {
    display: flex;
    flex-direction: row;
    height: var(--mobileHeightContent);
  }
  .boxTop {
    width: 100vw;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    top: 0px;
    left: 0px;
    z-index: 5;
    text-decoration: none;
  }
  @keyframes boxBottom {
    0% {
      right: 0px;
    }
    50% {
      right: 50px;
    }
    70% {
      right: 0px;
    }
    75% {
      right: 5px;
    }
    80% {
      right: 0px;
    }
  }

  @keyframes boxTop {
    0% {
      left: 0px;
    }
    50% {
      left: 50px;
    }
    70% {
      left: 0px;
    }
    75% {
      left: 5px;
    }
    80% {
      left: 0px;
    }
  }
}
