.align {
  height: inherit;
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}
.space {
  height: 30px;
}
