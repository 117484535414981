@import '@fontsource/noto-sans';
@import '@fontsource/roboto';

.background {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 4;
  background-color: black;
}
.image {
  position: absolute;
  opacity: 0%;
  z-index: 4;
  top: 0px;
  left: 0px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  object-fit: cover;
  object-position: top;
  filter: brightness(50%);
  transition: 3s;
  -webkit-transition: 3s;
  -moz-transition: 3s;
  -ms-transition: 3s;
  -o-transition: 3s;
}
/* .image:hover {
  cursor: pointer;
  filter: grayscale(50%);
  -webkit-filter: grayscale(50%);
} */
.titleContainer {
  z-index: 1000;
  position: absolute;
  right: 0px;
  z-index: 5;
  top: 0px;
  display: flex;
  height: 70vh;
  height: calc(var(--vh, 1vh) * 70);
  align-items: flex-end;
  flex-direction: column;
  padding-right: 4px;
}

.image:after {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.5;
}
.title {
  color: transparent;
  -webkit-text-stroke: 1px var(--white);
  text-align: end;
  font-family: "Roboto";
  font-size: 5vh;
  font-weight: 800;
  cursor: pointer;
  animation: flyIn 2s ease-in-out;
  -webkit-animation: flyIn 2s ease-in-out;
}

@keyframes flyIn {
  from {
    padding-bottom: 100px;
    transform: translate(0px, -80vh);
    -webkit-transform: translate(0px, -80vh);
    -moz-transform: translate(0px, -80vh);
    -ms-transform: translate(0px, -80vh);
    -o-transform: translate(0px, -80vh);
  }
  to {
    padding-bottom: 0px;

    transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
  }
}
.title:hover,
.active {
  cursor: pointer;
  color: var(--white);
}
.genre {
  color: snow;
  font-weight: 900;
  display: flex;
  font-size: 2.5vh;
  text-align: flex-end;
}
.genre p {
  width: fit-content;
}

.marginLeft {
  padding-left: 5vw;
}
.marginTop {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.active {
  opacity: 100;
}

.buttonContainer {
  height: 0px;
  transition: height 1s ease-in-out;
  overflow: hidden;
  -webkit-transition: height 1s ease-in-out;
  -moz-transition: height 1s ease-in-out;
  -ms-transition: height 1s ease-in-out;
  -o-transition: height 1s ease-in-out;
}
.buttonContainerTransition {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-direction: column;
  width: fit-content;
  max-width: 50vw;
  height: 220px;
}
@media only screen and (max-width: 600px) {
  .image {
    width: 100vw;
    max-width: 500px;
    object-fit: cover;
  }
  .container {
    display: flex;
    flex-direction: column;
    margin-bottom: 3vh;
    padding-bottom: 5vh;
    height: var(--mobileHeightContent);
    width: fit-content;
  }
  .titleContainer {
    position: absolute;
    right: 0px;
    z-index: 5;
    overflow: hidden;
    top: 15vh;
    top: calc(var(--vh, 1vh) * 15);
    height: var(--mobileHeightContent);
    display: flex;
    width: 100vw;
    align-items: flex-end;
    flex-direction: column;
    animation: test 3s linear 0s;
    padding-right: 4px;
  }

  .title {
    color: transparent;
    -webkit-text-stroke: 1px var(--white);
    text-align: end;
    font-family: "Roboto";
    font-size: 5vh;
    font-weight: 800;
    cursor: pointer;
    animation: flyIn 1.5s ease-in-out;
    -webkit-animation: flyIn 1.5s ease-in-out;
  }

  @keyframes flyIn {
    from {
      padding-bottom: 100px;
      transform: translate(0px, -100vh);
      -webkit-transform: translate(0px, -100vh);
      -moz-transform: translate(0px, -100vh);
      -ms-transform: translate(0px, -100vh);
      -o-transform: translate(0px, -100vh);
    }
    to {
      padding-bottom: 0px;

      transform: translate(0px, 0px);
      -webkit-transform: translate(0px, 0px);
      -moz-transform: translate(0px, 0px);
      -ms-transform: translate(0px, 0px);
      -o-transform: translate(0px, 0px);
    }
  }
  .buttonContainerTransition {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-direction: column;
    width: fit-content;
    max-width: 100vw;
    height: 200px;
  }
}
.text {
  display: block;
  color: snow;
  text-align: end;
}

/* .gallery {
  height: inherit;
  position: relative;
} */
.info {
  z-index: 6;
  position: absolute;
  font-size: 3vh;
  top: 40vh;
}
