.imageContainer {
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
  min-height: 0;
  object-fit: cover;
}
.imageContainer img {
  display: block;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}
.title {
  margin: unset;
}
.videoContainer {
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.active {
  cursor: pointer;
  padding-right: 40px;
  color: black;
  font-size: var(--fontSize);
  border: 1px solid black;
  opacity: 70%;
}
.text {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
}
.text p {
  position: absolute;

  margin-bottom: 10px;
  visibility: hidden;
  height: max-content;
  opacity: 0;
  animation-name: fadeIn;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 40s;
}
.text p:nth-child(1) {
  animation-delay: 0s;
}
.text p:nth-child(2) {
  animation-delay: 5s;
}
.text p:nth-child(3) {
  animation-delay: 10s;
}
.text p:nth-child(4) {
  animation-delay: 15s;
}
.text p:nth-child(5) {
  animation-delay: 20s;
}
.text p:nth-child(6) {
  animation-delay: 25s;
}
.text p:nth-child(7) {
  animation-delay: 30s;
}
.text p:nth-child(8) {
  animation-delay: 35s;
}
@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  2% {
    visibility: visible;
    opacity: 1;
  }
  11% {
    opacity: 1;
  }
  12% {
    visibility: hidden;
    opacity: 0;
  }
}
.section {
  scroll-snap-align: start;
  display: grid;
  grid-template-rows: auto 1fr 100px auto;
  row-gap: 2vh;
  grid-template-columns: 1fr;
  width: var(--contentWidth);
  height: 80vh;
  padding: 5vh 0;
  box-sizing: border-box;
}
.sectionText {
  scroll-snap-align: start;
  width: var(--contentWidth);
  padding: 5vh 0;
  box-sizing: border-box;
}
/* .container {
  scroll-snap-type: y proximity;
  overflow-y: scroll;
  box-sizing: border-box;
  height: 80vh;
  width: var(--contentWidth);
} */
.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.spaceBetween {
  justify-content: space-between;
  padding-bottom: 1px;
}

@media only screen and (max-width: 600px) {
  .section {
    width: 90vw;
    height: var(--mobileHeightContent);
    padding: 1vh 0px;
  }
  .sectionText {
    scroll-snap-align: start;
    width: 90vw;
    padding: 1vh 0px;
  }
  /*   .container {
    scroll-snap-type: y proximity;

    overflow-y: scroll;
    box-sizing: border-box;
    height: var(--mobileHeightContent);
    width: var(--contentWidth);
  } */
  /*   .sectionText {
    scroll-snap-align: start;
    width: 90vw;
    min-height: var(--mobileHeightContent);
    padding: 2vh 0;
    box-sizing: border-box;
  } */
}
