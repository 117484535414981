.imageContainer {
  /* overflow: hidden; */
  display: block;
  min-width: 0;
  min-height: 0;
}
.imageContainer img {
  display: block;
  object-fit: cover;
  max-width: 100%;
}
.title {
  margin: unset;
}

.videoContainer {
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.section {
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  grid-gap: 2vh;
  grid-template-columns: 1fr;
  align-items: flex-start;
  width: var(--contentWidth);

  box-sizing: border-box;
  padding: 5vh 0px;

  /*   border-bottom: 1px solid snow;
 */
}
.section:first-child {
  padding-top: 0px;
}
/* .section:last-child {
  border-bottom: none;
} */

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  /*   justify-content: space-between;
 */
}
.row:first-child {
  /*   scroll-snap-align: start;
 */
}
.devider {
  padding: 0px 3px;
  font-weight: 800;
  color: rosybrown;
}
.tag {
  font-weight: 800;
}
@media only screen and (max-width: 600px) {
  .section {
    width: unset;
  }
}
