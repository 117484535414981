.icon {
  width: 4vw;
  height: 4vw;
  cursor: pointer;
  color: var(--white);
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -o-transition: 0.5s ease;
}
.iconQuartet {
  width: 4vw;
  height: 4vw;
  cursor: pointer;

  color: rosybrown;
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -o-transition: 0.5s ease;
}
.iconQuartet:hover {
  /*color: #44364663;
  color: rgba(255, 255, 255, 0.589);
  */
  transform: translateY(-4px);
  color: rgba(188, 143, 143, 0.564);
}
.icon:hover {
  /*color: #44364663;
  color: rgba(255, 255, 255, 0.589);
  */
  transform: translateY(-4px);
  color: rgba(255, 255, 255, 0.678);
}

.container {
  /*   position: absolute; */
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: fit-content;
}
.containerQuartet {
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: fit-content;
}
.container > * {
  margin: 10px 0px;
  margin-right: 20px;
}
.containerQuartet > * {
  margin: 10px 0px;
  margin-left: 20px;
}

@media only screen and (max-width: 600px) {
  .icon {
    width: 6vh;
    height: 6vh;
    cursor: pointer;
    color: var(--white);
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
  }
  .iconQuartet {
    width: 6vh;
    height: 6vh;
    cursor: pointer;

    color: rosybrown;
    transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
  }
  .iconQuartet:hover {
    /*color: #44364663;
    color: rgba(255, 255, 255, 0.589);
    */
    transform: translateY(-4px);
    color: rgba(188, 143, 143, 0.564);
  }
  .icon:hover {
    /*color: #44364663;
    color: rgba(255, 255, 255, 0.589);
    */
    transform: translateY(-4px);
    color: rgba(255, 255, 255, 0.678);
  }

  .container {
    /*   position: absolute; */
    display: flex;
    flex-direction: row;
    height: auto;
  }
  .containerQuartet {
    display: flex;
    flex-direction: row;
    height: auto;
  }
  .container > * {
    margin: 10px 0px;
    margin-left: 20px;
  }
  .containerQuartet > * {
    margin: 10px 0px;
    margin-right: 20px;
  }
}
