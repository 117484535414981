.imageContainer {
  /* overflow: hidden; */
  display: block;
  min-width: 0;
  min-height: 0;
}
.imageContainer img {
  display: block;
  object-fit: cover;
  max-width: 100%;
}
.title {
  margin: unset;
}
.videoContainer {
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.text {
  margin-bottom: 5vh;
}

.section {
  scroll-snap-align: start;
  display: grid;
  grid-gap: 2vh;
  grid-template-columns: 1fr;
  overflow: hidden;
  align-items: center;
  width: 50vw;
  min-height: 80vh;
  box-sizing: border-box;
}
.container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  box-sizing: border-box;
  height: 88vh;
  width: 50vw;
  animation: fadeIn 2s linear;
  -webkit-animation: fadeIn 2s linear;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  z-index: 0;
  text-align: start;
  line-height: 1.6;
  letter-spacing: 0.1em;
  overflow: hidden;
}
.container4tet {
  position: absolute;
  top: 12vh;
  left: 0px;
  height: 80vh;
  width: 50vw;
  overflow: scroll;
  color: black;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (max-width: 600px) {
  .container {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    box-sizing: border-box;
    height: var(--mobileHeightContent);
    width: 90vw;
    animation: fadeIn 2s linear;
    -webkit-animation: fadeIn 2s linear;
  }
  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    z-index: 0;
    text-align: start;
    line-height: 1.6;
    letter-spacing: 0.1em;
    overflow: hidden;
  }
  .container4tet {
    position: absolute;
    top: 15vh;
    left: 0px;
    height: var(--mobileHeightContent);
    width: 100vw;
    overflow: scroll;
    color: black;
  }
  .section {
    scroll-snap-align: start;
    display: grid;
    grid-gap: 2vh;
    grid-template-columns: 1fr;
    overflow: hidden;
    align-items: center;
    width: 90vw;
    min-height: var(--mobileHeightContent);
  }
}
